import { z } from 'zod';
import {
  BookingConfirmMutation,
  BookingCourseInput,
  ShopForBookingQuery
} from '@/graphql/generated/graphql';
import { TEL_REG } from '@/utils/regexp';

// 予約用店舗の情報
export type ShopForBooking = NonNullable<ShopForBookingQuery['shop']>;
// 予約用店舗のPMのコースカテゴリー情報
export type PmCourseCategoryForBooking = NonNullable<ShopForBooking['pmCourseCategories']>[number];
// 予約用店舗のPMのコース情報
export type PmCourseForBooking = NonNullable<PmCourseCategoryForBooking>['courses'][number];
// 予約用店舗のPMのコースオプション情報
export type PmCourseOptionForBooking = NonNullable<PmCourseCategoryForBooking>['options'][number];
// 予約用店舗のPMのスタッフ情報
export type PmStaffsForBooking = NonNullable<ShopForBookingQuery['shop']>['staffs'];
export type PmStaffForBooking = ShopForBooking['staffs'][number];
// 予約確認用の情報
export type BookingConfirm = BookingConfirmMutation['bookingConfirm']['bookingConfirmInfo'];


// 予約データに利用する店舗データ
export type BookingInputShop = ShopForBooking;
// 予約データに利用するコースデータ
export type BookingInputCourse = BookingCourseInput;
// 予約データに利用する確認データ
export type BookingInputConfirm = BookingConfirm;
// 予約データに利用するスタッフオプションデータ
export type BookingInputStaffOption = {
  isStaff: boolean;
  pmStaffId: string | null;
  stickId: number | null;
  name: string;
  nickname: string;
  sex: number | null;
  price: number | null;
  imageUrl: string | null;
};
// 予約データに利用するトラッキングパラメータのデータ
export type BookingInputTrackingParameter = {
  utmId: string | null,
  utmSource: string | null,
  utmMedium: string | null,
  utmCampaign: string | null,
}
// 予約データの全体
export interface BookingInputState {
  shop: BookingInputShop | null;
  confirmInfo: BookingInputConfirm | null;
  courses: BookingInputCourse[];
  stickId: number | null;
  pmStaffId: string | null;
  bookingAt: string | null;
  comment: string;
  trackingParameter: BookingInputTrackingParameter;
  isBooked: boolean;
  isRequireConfirmRedirect: boolean;
}

const courseSchema = z.object({
  pmCourseId: z.any().nullable().optional(),
  courseMinutes: z.number(),
  name: z.string(),
  price: z.number(),
  noTaxPrice: z.number(),
  remarks: z.string(),
  activeMale: z.boolean(),
  activeFemale: z.boolean(),
  imageUrl: z.string().nullable().optional(),
});

export type CourseSchema = z.infer<typeof courseSchema>;

const courseOptionSchema = z.object({
  name: z.string(),
  pmCourseId: z.string(),
  courseMinutes: z.number(),
  price: z.number(),
  noTaxPrice: z.number(),
  activeMale: z.boolean(),
  activeFemale: z.boolean(),
});

export type CourseOptionSchema = z.infer<typeof courseOptionSchema>;

const courseCategorySchema = z.object({
  name: z.string(),
  pmCourseCategoryId: z.string(),
  remarks: z.string(),
  isOption: z.boolean(),
  imageUrl: z.string().optional().nullable(),
  courses: z.array(courseSchema),
  options: z.array(courseOptionSchema)
});

export type CourseCategorySchema = z.infer<typeof courseCategorySchema>;

const staffSchema = z.object({
  name: z.string(),
  nickname: z.string(),
  sex: z.number(),
  pmStaffId: z.string(),
  nominatePrice: z.number().nullable().optional(),
  nominateOptionName: z.string().nullable().optional(),
  canBeNominated: z.boolean(),
  canBeNominatedBySex: z.boolean(),
  imageUrl: z.string().optional().nullable(),
});

export type StaffSchema = z.infer<typeof staffSchema>;

const shopSchema = z.object({
  id: z.number(),
  slug: z.string(),
  name: z.string(),
  isRequiredAdmissionFee: z.boolean(),
  isPayAvailable: z.boolean(),
  pmShopId: z.any().nullable().optional(),
  address: z.string().nullable().optional(),
  tel: z.string().nullable().optional(),
  pmCourseCategories: z.array(courseCategorySchema),
  staffs: z.array(staffSchema),
  isBookable: z.boolean(),
  hasOutsourcedStaff: z.boolean(),
  shopSettings: z.object({
    closed: z.boolean(),
    maleDisplay: z.boolean(),
    maleDisplayName: z.string().nullable().optional(),
    maleNominatePrice: z.number().nullable().optional(),
    maleStickId: z.number().nullable().optional(),
    femaleDisplay: z.boolean(),
    femaleDisplayName: z.string().nullable().optional(),
    femaleNominatePrice: z.number().nullable().optional(),
    femaleStickId: z.number().nullable().optional(),
  }).nullable().optional(),
});

export type ShopSchema = z.infer<typeof shopSchema>;

export const bookingInputValidationSchema = z.object({
  shop: shopSchema.nullable(),
  confirmInfo: z.object({
    isBookable: z.boolean(),
    totalPrice: z.number(),
    hasCourseIdsChanged: z.boolean(),
    bookingMenu: z.array(z.object({
      pmCourseCategoryId: z.string(),
      name: z.string(),
      courses: z.array(z.object({
        pmCourseId: z.string(),
        name: z.string(),
        price: z.number(),
      })),
      options: z.array(z.object({
        pmCourseId: z.string(),
        name: z.string(),
        price: z.number(),
      })),
    })),
    nominateInfo: z.object({
      courseOptionId: z.string().nullable().optional(),
      courseOptionName: z.string().nullable().optional(),
      price: z.number().nullable().optional(),
    }).nullable().optional(),
    courseParams: z.array(z.object({
      pmCourseCategoryId: z.string().nullable().optional(),
      pmCourseId: z.string().nullable().optional(),
      pmCourseOptionIds: z.array(z.string()).nullable().optional(),
    }))
  }).nullable(),
  courses: z.array(
    z.object({
      pmCourseCategoryId: z.string(),
      pmCourseId: z.string(),
      pmCourseOptionIds: z.array(z.string()),
    })
  ),
  stickId: z.number().nullable(),
  pmStaffId: z.string().nullable(),
  bookingAt: z.string().nullable(),
  comment: z.string(),
  trackingParameter: z.object({
    utmId: z.string().nullable(),
    utmSource: z.string().nullable(),
    utmMedium: z.string().nullable(),
    utmCampaign: z.string().nullable(),
  }),
  isBooked: z.boolean(),
  // 確認画面からどこかログイン or 会員登録をした場合はTrueにしておく
  isRequireConfirmRedirect: z.boolean(),
});

export type BookingInputValidationSchema = z.infer<typeof bookingInputValidationSchema>;

export const guestBookingFormValidationSchema = z.object({
  name: z.string()
    .min(1, { message: '入力してください' }),
  email: z.string()
    .email({ message: 'メールアドレスの形式が正しくありません' }),
  // 電話番号は必須ではないので、空文字列も許容する
  sex: z.string(),
  tel: z.string()
    .regex(TEL_REG, { message: '半角数字のみで入力してください' })
    .or(z.literal('')),
  termsAccepted: z.boolean().refine((value) => value, {
    message: '利用規約への同意は必須です',
  }),
  privacyAccepted: z.boolean().refine((value) => value, {
    message: 'プライバシーポリシーへの同意は必須です',
  }),
});

export type GuestBookingFormValidationSchema = z.infer<typeof guestBookingFormValidationSchema>;
