import {
  PhoneIcon
} from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';
import { SnsLinks } from '../SnsLinks/SnsLinks';
import { SpFooterLink } from './SpFooterLink';
import { Image } from '@/components/Image';
import { TextLink } from '@/components/Link';
import {
  getPageInfo,
  PageName
} from '@/config/pages';

export type SpFooterItemsProps = {};

const menus: PageName[] = [
  'studio',
  'news',
  'faq',
  'recruit',
  'contact',
  'operating_company',
  'privacy_policy',
  'tokushoho',
  'guide',
  'education',
  'brand',
  'brand/reraku',
  'card',
  'blog',
  'about_rerakupay',
];

export const SpFooterHeight = 760;

export const SpFooterItems = ({ }: SpFooterItemsProps) => {
  return (
    <Box
      minH={`${SpFooterHeight}px`}
      bg={'brand.500'}
      color={'white'}
      position={'relative'}
    >
      <Flex
        flexDirection={'column'}
      >
        <Flex
          flexWrap={'wrap'}
          mx={6}
          my={10}
        >
          {menus.map((menu) => (
            <SpFooterLink
              key={menu}
              pageLink={getPageInfo(menu, {})}
            />
          ))}
        </Flex>
        <Box
          textAlign={'left'}
          margin={'0 auto'}
          px={6}
        >
          <Box
            display={'inline-flex'}
            alignItems={'baseline'}
            pb={2}
          >
            <Text
              textStyle={'h3'}
              fontWeight={'bold'}
            >
              予約受付センター
            </Text>
            <Text>【受付時間】9:30~20:30</Text>
          </Box>
          <Text
            textStyle={'h6'}
            pb={3}
          >
            ご来店希望の店舗名をお申し付けください。
          </Text>
          <Box
            textStyle={'h2'}
            fontWeight={'bold'}
            pb={3}
          >
            <TextLink
              color={'white'}
              href={'tel:0366292979'}
            >
              <PhoneIcon
                mb={1}
                mr={3}
              />
              03-6629-2979
            </TextLink>
          </Box>
          <Text
            textStyle={'h9'}
          >
            番号をよくお確かめの上、お間違いのないようにお願いいたします。<br/>
            ※一部対応してない店舗もございます。あらかじめご了承ください。
          </Text>
        </Box>
      </Flex>
      <Box
        w={'full'}
        textAlign={'center'}
        position={'absolute'}
        bottom={3}
      >
        <Box
          width={'fit-content'}
          mx={'auto'}
          mb={4}
        >
          <Image
            src={'/images/logo/footer_logo.png'}
            alt={'rerakuロゴ ホワイト'}
            width={250}
            height={45}
          />
        </Box>
        <Text>&copy; MEDIROM Wellness Co. All Right Reserved.</Text>
        <Box
          pt={5}
        >
          <SnsLinks/>
        </Box>
      </Box>
    </Box>
  );
};
