import {
  useCallback,
  useState
} from 'react';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { PageNames } from '@/config/pages';
import {
  RecoilAtomKeys,
  RecoilSelectorKeys
} from '@/config/recoileKeys';
import {
  GraphQLValidationError,
  ValidationError
} from '@/graphql/errors';
import {
  BookingConfirmInput,
  BookingCourseInput,
  BookingInputMutation,
  BookingInputParams,
  TimeTablesByShopQueryVariables,
  useBookingConfirmMutation,
  useBookingInputMutation,
  useBookingGuestInputMutation,
  BookingGuestInputMutation,
  GuestCustomerInput,
} from '@/graphql/generated/graphql';
import {
  syncSessionStorageEffect,
} from '@/lib/recoil/syncSessionStorage';
import {
  bookingInputValidationSchema,
  BookingInputStaffOption,
  PmCourseCategoryForBooking,
  PmCourseForBooking,
  PmCourseOptionForBooking,
  ShopForBooking,
  BookingConfirm,
  BookingInputState,
  BookingInputTrackingParameter,
  BookingInputCourse,
} from '@/types/bookingInput';
import { dayjs } from '@/utils/dayjs';

export const defaultBookingInput: BookingInputState = {
  shop: null,
  confirmInfo: null,
  courses: [],
  pmStaffId: null,
  stickId: null,
  bookingAt: null,
  comment: '',
  trackingParameter: {
    utmId: null,
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
  },
  isBooked: false,
  isRequireConfirmRedirect: false,
};

export const bookingInputState = atom<BookingInputState>({
  key: RecoilAtomKeys.BOOKING_INPUT,
  default: defaultBookingInput,
  effects: [
    syncSessionStorageEffect(RecoilAtomKeys.BOOKING_INPUT, bookingInputValidationSchema),
  ]
});

// ページ情報を取得する際に利用する変数
const bookingPageVariablesSelector = selector({
  key: RecoilSelectorKeys.BOOKING_PAGE_VARIABLES,
  get: ({ get }): PageNames['studio/{slug}'] => {
    const { shop } = get(bookingInputState);
    if (!shop) return {
      slug: 'ZZZZZZZZZZ',
      name: 'ダミー店舗',
    };

    return shop;
  }
});

// PMのコースIDの配列を取得
const selectedPmCourseIdsSelector = selector<string[]>({
  key: RecoilSelectorKeys.PM_COURSE_IDS,
  get: ({ get }) => {
    return get(bookingInputState).courses.map(({ pmCourseId }) => pmCourseId);
  },
});

// PMのコースオプションIDの配列を取得
const pmCourseOptionIdsSelector = selectorFamily<string[], string>({
  key: RecoilSelectorKeys.PM_COURSE_OPTION_IDS,
  get: (pmCourseId) => ({ get }) => {
    const { courses, shop } = get(bookingInputState);
    if (!shop) return [];

    const course = courses.find(c => c.pmCourseId === pmCourseId);
    if (!course) return [];

    const pmCourseCategory = shop.pmCourseCategories
      .find(({ pmCourseCategoryId }) => pmCourseCategoryId === course.pmCourseCategoryId);
    if (!pmCourseCategory) return [];

    return pmCourseCategory.options.map(({ pmCourseId }) => pmCourseId)
      .filter(pmCourseId => course.pmCourseOptionIds.includes(pmCourseId));
  },
});

// コースカテゴリーを配列で取得
const courseCategoriesSelector = selector<PmCourseCategoryForBooking[]>({
  key: RecoilSelectorKeys.COURSE_CATEGORIES,
  get: ({ get }) => {
    const { shop } = get(bookingInputState);
    if (!shop) return [];

    const pmCourseIds = get(selectedPmCourseIdsSelector);
    return shop.pmCourseCategories
      .filter(pmCourseCategory =>
        pmCourseCategory.courses.map(course => course.pmCourseId)
          .some(pmCourseId => pmCourseIds.includes(pmCourseId)));
  },
});

// コースを配列で取得
const coursesSelector = selector<PmCourseForBooking[]>({
  key: RecoilSelectorKeys.COURSE,
  get: ({ get }) => {
    const { shop } = get(bookingInputState);

    if (!shop) return [];
    const pmCourseIds = get(selectedPmCourseIdsSelector);
    return shop.pmCourseCategories
      .map(({ courses }) => courses).flat()
      .filter(course => pmCourseIds.includes(course.pmCourseId));
  },
});

// コースオプションの配列を取得
const courseOptionsSelector = selectorFamily<PmCourseOptionForBooking[], string>({
  key: RecoilSelectorKeys.COURSE_OPTIONS,
  get: (pmCourseId) => ({ get }) => {
    const { courses, shop } = get(bookingInputState);
    if (!shop) return [];

    const course = courses.find(c => c.pmCourseId === pmCourseId);
    if (!course) return [];

    const pmCourseCategory = shop.pmCourseCategories.find(({ pmCourseCategoryId }) => pmCourseCategoryId === course.pmCourseCategoryId);
    if (!pmCourseCategory) return [];

    return pmCourseCategory.options.filter(({ pmCourseId }) => course.pmCourseOptionIds.includes(pmCourseId));
  },
});

// コースの数を取得
const courseCountSelector = selector<number>({
  key: RecoilSelectorKeys.COURSE_COUNT,
  get: ({ get }) => {
    const pmCourseIds = get(selectedPmCourseIdsSelector);
    return pmCourseIds.length;
  },
});

// オプションの数を取得
const courseOptionCountSelector = selector<number>({
  key: RecoilSelectorKeys.COURSE_OPTION_COUNT,
  get: ({ get }) => {
    const { courses } = get(bookingInputState);
    return courses.reduce((acc, { pmCourseOptionIds }) => acc + pmCourseOptionIds.length, 0);
  },
});

// 合計施術時間
const totalMinutesSelector = selector<number>({
  key: RecoilSelectorKeys.TOTAL_MINUTES,
  get: ({ get }) => {
    const courses = get(coursesSelector);

    return courses.reduce((acc, { pmCourseId, courseMinutes }) => {
      acc += courseMinutes;
      const options = get(courseOptionsSelector(pmCourseId));
      acc += options.reduce((acc, { courseMinutes }) => {
        acc += courseMinutes;
        return acc;
      }, 0);
      return acc;
    }, 0);
  },
});

// 合計金額
const selectedTotalPriceSelector = selector<number>({
  key: RecoilSelectorKeys.TOTAL_PRICE,
  get: ({ get }) => {
    const courses = get(coursesSelector);

    return courses.reduce((acc, { pmCourseId, price }) => {
      acc += price;
      const options = get(courseOptionsSelector(pmCourseId));
      acc += options.reduce((acc, { price }) => {
        acc += price;
        return acc;
      }, 0);
      return acc;
    }, 0);
  },
});

// 予約日時をフォーマットした文字列
const formattedBookingAtSelector = selector<string>({
  key: RecoilSelectorKeys.FORMATTED_BOOKING_AT,
  get: ({ get }) => {
    const { bookingAt } = get(bookingInputState);
    return bookingAt ? dayjs(bookingAt).format('YYYY/MM/DD(ddd) HH:mm') : '';
  },
});

// スタッフのオプションの配列を取得
const staffOptionsSelector = selector({
  key: RecoilSelectorKeys.STAFF_OPTIONS,
  get: ({ get }): BookingInputStaffOption[] => {
    const staffOptions: BookingInputStaffOption[] = [{
      isStaff: false,
      pmStaffId: null,
      stickId: null,
      name: '指名なし',
      nickname: '指名なし',
      sex: null,
      price: 0,
      imageUrl: '',
    }];

    const { shop } = get(bookingInputState);
    if (!shop) return [];

    const { shopSettings } = shop;

    if (shopSettings && shopSettings.maleDisplay) {
      staffOptions.push({
        isStaff: false,
        pmStaffId: null,
        stickId: shopSettings.maleStickId ?? null,
        name: shopSettings.maleDisplayName ?? '',
        nickname: shopSettings.maleDisplayName ?? '',
        sex: 0,
        price: shopSettings.maleNominatePrice ?? null,
        imageUrl: '',
      });
    }

    if (shopSettings && shopSettings.femaleDisplay) {
      staffOptions.push({
        isStaff: false,
        pmStaffId: null,
        stickId: shopSettings.femaleStickId ?? null,
        name: shopSettings.maleDisplayName ?? '',
        nickname: shopSettings.femaleDisplayName ?? '',
        sex: 1,
        price: shopSettings.femaleNominatePrice ?? null,
        imageUrl: '',
      });
    }

    return [
      ...staffOptions,
      ...(
        shop.staffs
          .map((staff) => {
            return {
              isStaff: true,
              pmStaffId: staff.pmStaffId,
              stickId: null,
              name: staff.name,
              nickname: staff.nickname,
              sex: staff.sex,
              price: staff.nominatePrice ?? null,
              imageUrl: staff.imageUrl ?? null,
            };
          })
          .sort((a, b) => a.pmStaffId - b.pmStaffId)
      )
    ];
  },
});

const staffOptionSelector = selector({
  key: RecoilSelectorKeys.STAFF_OPTION,
  get: ({ get }): BookingInputStaffOption => {
    const { pmStaffId, stickId } = get(bookingInputState);
    const staffOptions = get(staffOptionsSelector);

    const staffOption = staffOptions.find((staffOption) =>
      staffOption.pmStaffId === pmStaffId && staffOption.stickId === stickId);

    if (staffOption) return staffOption;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return staffOptions.find((staffOption) =>
      staffOption.pmStaffId === null && staffOption.stickId === null)!;
  }
});

// 枠検索時に利用するコースのパラメータ
const courseParamsSelector = selector({
  key: RecoilSelectorKeys.COURSE_PARAMS,
  get: ({ get }): BookingCourseInput[] => {
    const { courses } = get(bookingInputState);
    return courses;
  }
});

// 枠検索時に利用するパラメータの取得
const timeTablesByShopParamsSelector = selector({
  key: RecoilSelectorKeys.TIME_TABLES_BY_SHOP_SHOP_PARAMS,
  get: ({ get }): Omit<TimeTablesByShopQueryVariables, 'startDate' | 'viewDay'> | null => {
    const { shop, stickId, pmStaffId } = get(bookingInputState);
    if (!shop) return null;

    const courses = get(courseParamsSelector);
    return {
      courses,
      pmShopId: shop.pmShopId,
      staffId: pmStaffId,
      staffSexId: stickId,
    };
  }
});

// 確認画面で利用するパラメータの取得
const bookingConfirmParamsSelector = selector({
  key: RecoilSelectorKeys.BOOKING_CONFIRM_PARAMS,
  get: ({ get }): BookingConfirmInput | null => {
    const { shop, bookingAt, stickId, pmStaffId } = get(bookingInputState);
    if (!shop || !bookingAt) return null;

    const bookingAtDayjs = dayjs(bookingAt);
    const courses = get(courseParamsSelector);
    return {
      courses,
      date: bookingAtDayjs.format('YYYY-MM-DD'),
      pmShopId: shop.pmShopId,
      pmStaffId,
      staffSexId: stickId,
      startTime: bookingAtDayjs.format('HH:mm'),
    };
  }
});

// 予約で利用するパラメータの取得
const bookingParamsSelector = selector({
  key: RecoilSelectorKeys.BOOKING_PARAMS,
  get: ({ get }): BookingInputParams | null => {
    const {
      shop,
      bookingAt,
      confirmInfo,
      stickId,
      pmStaffId,
      comment,
      trackingParameter,
    } = get(bookingInputState);
    const courses = get(courseParamsSelector);
    if (!shop || !bookingAt || !confirmInfo || courses.length === 0) return null;

    const bookingAtDayjs = dayjs(bookingAt);
    return {
      date: bookingAtDayjs.format('YYYY-MM-DD'),
      startTime: bookingAtDayjs.format('HH:mm'),
      courses,
      pmShopId: shop.pmShopId,
      pmStaffId,
      staffSexId: stickId,
      comment,
      trackingParameter,
    };
  }
});

// 予約可否取得
const isBookableSelector = selector({
  key: RecoilSelectorKeys.IS_BOOKABLE,
  get: ({ get }): boolean => {
    const { confirmInfo } = get(bookingInputState);
    return !!(confirmInfo && confirmInfo.isBookable);
  }
});

// STEP2にすすめるか
const canMoveStep2Selector = selector({
  key: RecoilSelectorKeys.CAN_MOVE_STEP2,
  get: ({ get }): boolean => {
    const { shop, courses, isBooked } = get(bookingInputState);

    //ここではcourseCategory単位のOptionを指す
    let optionOnly = true;
    if (shop) {
      const selectedCourseCategories = shop.pmCourseCategories.filter(courseCategory => {
        return courses.some(course => course.pmCourseCategoryId === courseCategory.pmCourseCategoryId);
      });
      // selectedCategoriesの中でオプションではないコースが一つでも選択されていればoptionOnlyをfalseにする
      for (const courseCategory of selectedCourseCategories) {
        if (!courseCategory.isOption) {
          optionOnly = false;
          break;
        }
      }
    }

    return !!(shop && courses.length > 0 && !isBooked && !optionOnly);
  }
});

// STEP3にすすめるか
const canMoveStep3Selector = selector({
  key: RecoilSelectorKeys.CAN_MOVE_STEP3,
  get: ({ get }): boolean => {
    const step2 = get(canMoveStep2Selector);
    const { bookingAt } = get(bookingInputState);
    return !!(step2 && bookingAt);
  }
});

// STEP3にすすめるか
const shouldRedirectToStep3Selector = selector({
  key: RecoilSelectorKeys.SHOULD_REDIRECT_TO_STEP3,
  get: ({ get }): boolean => {
    const step3 = get(canMoveStep3Selector);
    const { isRequireConfirmRedirect } = get(bookingInputState);
    return (step3 && isRequireConfirmRedirect);
  }
});

export const recoilBookingInputSelectors = {
  useShop: () => useRecoilValue(bookingInputState).shop,
  useBookingAt: () => useRecoilValue(bookingInputState).bookingAt,
  useConfirmInfo: () => useRecoilValue(bookingInputState).confirmInfo,
  useIsBooked: () => useRecoilValue(bookingInputState).isBooked,
  useIsRequireConfirmRedirect: () => useRecoilValue(bookingInputState).isRequireConfirmRedirect,
  usePmCourseIds: () => useRecoilValue(selectedPmCourseIdsSelector),
  usePmCourseOptionIds: (pmCourseId: string) => useRecoilValue(pmCourseOptionIdsSelector(pmCourseId)),
  useCourseCategories: () => useRecoilValue(courseCategoriesSelector),
  useCourses: () => useRecoilValue(coursesSelector),
  useCourseCount: () => useRecoilValue(courseCountSelector),
  useCourseOptionCount: () => useRecoilValue(courseOptionCountSelector),
  useTotalMinutes: () => useRecoilValue(totalMinutesSelector),
  useTotalPrice: () => useRecoilValue(selectedTotalPriceSelector),
  useFormattedBookingAt: () => useRecoilValue(formattedBookingAtSelector),
  useStaffOptions: () => useRecoilValue(staffOptionsSelector),
  useStaffOption: () => useRecoilValue(staffOptionSelector),
  useComment: () => useRecoilValue(bookingInputState).comment,
  useTimeTablesByShopParams: () => useRecoilValue(timeTablesByShopParamsSelector),
  useBookingParams: () => useRecoilValue(bookingParamsSelector),
  useBookingPageVariables: () => useRecoilValue(bookingPageVariablesSelector),
  useBookable: () => useRecoilValue(isBookableSelector),
  useCanMoveStep2: () => useRecoilValue(canMoveStep2Selector),
  useCanMoveStep3: () => useRecoilValue(canMoveStep3Selector),
  useShouldRedirectToStep3: () => useRecoilValue(shouldRedirectToStep3Selector),
};

export const recoilBookingInputActions = {
  // 店舗をセット
  useSetShop: () => (
    useRecoilCallback(({ set }) => (shop: ShopForBooking | null) => {
      set(bookingInputState, (prev) => {
        return shop && shop.pmShopId !== prev.shop?.pmShopId
        ? {
          ...defaultBookingInput,
          shop,
        } : {
          ...prev,
          shop,
        };
      });
    }, [])
  ),

  // 予約履歴から店舗、コース、、オプション、スタッフをセット
  useSetBookingHistoryCourses: () => {
    const setState = useSetRecoilState(bookingInputState);
    return useCallback((
      shop: ShopForBooking,
      newCourses: BookingInputCourse[],
      pmStaffId: BookingInputStaffOption['pmStaffId']
    ) => {
      setState((prev) => {
        // 予約可能なオプション一覧を取得する
        const bookableOptions = shop.pmCourseCategories.flatMap(pmCourseCategory =>
          pmCourseCategory.options.map(option => option.pmCourseId)
        );

        const nextCourses = newCourses.map(course => ({
          pmCourseId: course.pmCourseId,
          pmCourseCategoryId: course.pmCourseCategoryId,
          pmCourseOptionIds: course.pmCourseOptionIds.filter(optionId => bookableOptions.includes(optionId)),
        }));

        const foundStaff = shop.staffs.find(staff => staff.pmStaffId === pmStaffId);
        const designedStaffId = foundStaff ? foundStaff.pmStaffId : null;

        return {
          ...prev,
          shop,
          selectedHalfHourTime: null,
          bookingAt: null,
          courses: nextCourses,
          pmStaffId: designedStaffId,
          stickId: null,
        };
      });
    }, [setState]);
  },

  // コースを追加・削除
  useAddOrRemoveCourse: () => {
    const setState = useSetRecoilState(bookingInputState);
    return useCallback((
      course: PmCourseForBooking,
      courseCategory: PmCourseCategoryForBooking,
      isAdd = true
    ) => {
      setState((prev) => {
        const { shop, courses } = prev;
        if (!shop) return prev;

        const shopCourseCategory = shop.pmCourseCategories
          .find(({ pmCourseCategoryId, courses }) =>
            pmCourseCategoryId === courseCategory.pmCourseCategoryId
            && courses.some(({ pmCourseId }) => pmCourseId === course.pmCourseId)
          );

        // 選択されたコースカテゴリーが店舗のコースに存在しない場合は何もしない
        if (!shopCourseCategory) return prev;

        let nextCourses = [...courses];

        if (isAdd) {
          nextCourses = [
            ...(nextCourses.filter(({ pmCourseCategoryId }) => pmCourseCategoryId !== courseCategory.pmCourseCategoryId)),
            {
              pmCourseId: course.pmCourseId,
              pmCourseCategoryId: shopCourseCategory.pmCourseCategoryId,
              pmCourseOptionIds: [],
            }
          ];
        } else {
          nextCourses = nextCourses.filter(({ pmCourseId }) => pmCourseId !== course.pmCourseId);
        }

        return {
          ...prev,
          selectedHalfHourTime: null,
          bookingAt: null,
          courses: nextCourses,
          pmCourseOptionIds: [],
          stickId: null,
        };
      });
    }, [setState]);
  },

  // コースオプションを追加・削除
  useAddOrRemoveCourseOption: () => {
    const setState = useSetRecoilState(bookingInputState);
    return useCallback((
      courseOption: PmCourseOptionForBooking,
      courseCategory: PmCourseCategoryForBooking,
      course: PmCourseForBooking,
      isAdd = true
    ) => {
      setState((prev) => {
        const { shop, courses } = prev;
        if (!shop) return prev;

        const shopCourseCategory = shop.pmCourseCategories
          .find(({ pmCourseCategoryId, courses, options }) =>
            pmCourseCategoryId === courseCategory.pmCourseCategoryId
            && courses.some(({ pmCourseId }) => pmCourseId === course.pmCourseId)
            && options.some(({ pmCourseId }) => pmCourseId === courseOption.pmCourseId)
          );

        // 選択されたオプションが店舗に存在しない場合は何もしない
        if (!shopCourseCategory) return prev;

        const selectedCourse = courses.find(c =>
          c.pmCourseId === course.pmCourseId && c.pmCourseCategoryId === courseCategory.pmCourseCategoryId);

        // 選択されたオプションが、選択されているコースに存在しない場合は何もしない
        if (!selectedCourse) return prev;

        let nextCourses = [...courses];

        if (isAdd) {
          nextCourses = [
            ...nextCourses.filter(({ pmCourseId, pmCourseCategoryId }) =>
              !(pmCourseId === course.pmCourseId && pmCourseCategoryId === courseCategory.pmCourseCategoryId)),
            {
              ...selectedCourse,
              pmCourseOptionIds: Array.from(new Set([
                ...selectedCourse.pmCourseOptionIds,
                courseOption.pmCourseId,
              ])),
            }
          ];
        } else {
          nextCourses = [
            ...nextCourses.filter(({ pmCourseId, pmCourseCategoryId }) =>
              !(pmCourseId === course.pmCourseId && pmCourseCategoryId === courseCategory.pmCourseCategoryId)),
            {
              ...selectedCourse,
              pmCourseOptionIds: selectedCourse.pmCourseOptionIds.filter(pmCourseOptionId => pmCourseOptionId !== courseOption.pmCourseId),
            }
          ];
        }

        return {
          ...prev,
          selectedHalfHourTime: null,
          bookingAt: null,
          courses: nextCourses,
          stickId: null,
        };
      });
    }, [setState]);
  },

  // スタッフをセット
  useSetStaffOption: () => (
    useRecoilCallback(({ set }) => (
      staffOption: BookingInputStaffOption
    ) => {
      const bookingInput: Partial<BookingInputState> = {
        bookingAt: null,
      };
      if (staffOption.pmStaffId) {
        set(bookingInputState, (prev) => {
          return {
            ...prev,
            pmStaffId: staffOption.pmStaffId,
            stickId: null,
            ...bookingInput,
          };
        });
      } else if (staffOption.stickId) {
        set(bookingInputState, (prev) => {
          return {
            ...prev,
            pmStaffId: null,
            stickId: staffOption.stickId,
            ...bookingInput,
          };
        });
      } else {
        set(bookingInputState, (prev) => {
          return {
            ...prev,
            pmStaffId: null,
            stickId: null,
            ...bookingInput,
          };
        });
      }
    }, [])
  ),

  // 予約日時をセット
  useSetBookingAt: () => (
    useRecoilCallback(({ set }) => (
      bookingAt: dayjs.Dayjs | null
    ) => {
      set(bookingInputState, (prev) => {
        return {
          ...prev,
          bookingAt: bookingAt ? bookingAt.format('YYYY-MM-DD HH:mm:ss') : null,
        };
      });
    }, [])
  ),

  useSetConfirmInfo: () => (
    useRecoilCallback(({ set }) => (
      confirmInfo: BookingConfirm | null
    ) => {
      set(bookingInputState, (prev) => {
        return {
          ...prev,
          confirmInfo,
        };
      });
    }, [])
  ),

  useSetComment: () => (
    useRecoilCallback(({ set }) => (
      comment: string
    ) => {
      set(bookingInputState, (prev) => {
        return {
          ...prev,
          comment
        };
      });
    }, [])
  ),

  useSetTrackingParameter: () => (
    useRecoilCallback(({ set }) => (
      trackingParameter: BookingInputTrackingParameter
    ) => {
      set(bookingInputState, (prev) => {
        return {
          ...prev,
          trackingParameter
        };
      });
    }, [])
  ),

  // 確認画面へ強制リダイレクトさせるフラグを有効化
  useSetIsRequireConfirmRedirect: () => (
    useRecoilCallback(({ set }) => (
      isRequireConfirmRedirect: boolean
    ) => {
      set(bookingInputState, (prev) => {
        return {
          ...prev,
          isRequireConfirmRedirect,
        };
      });
    }, [])
  ),

  // 予約済みにする
  useBooked: () => (
    useRecoilCallback(({ set }) => () => {
      set(bookingInputState, (prev) => ({
        ...prev,
        isBooked: true
      }));
    }, [])
  ),

  // 予約済みを解除
  useUnBooked: () => (
    useRecoilCallback(({ set }) => () => {
      set(bookingInputState, (prev) => ({
        ...prev,
        isBooked: false
      }));
    }, [])
  ),

  useReset: () => (
    useRecoilCallback(({ set }) => () => {
      set(bookingInputState, () => defaultBookingInput);
    }, [])
  )
};

// 予約確認実行
export const useConfirmBookingAction = () => {
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const mutation = useBookingConfirmMutation();
  const bookingConfirmParams = useRecoilValue(bookingConfirmParamsSelector);
  const setConfirmInfo = recoilBookingInputActions.useSetConfirmInfo();
  const mutate = useCallback(async () => {
    if (!bookingConfirmParams) return;

    try {
      const {
        bookingConfirm: {
          bookingConfirmInfo,
          result
        }
      } = await mutation.mutateAsync({
        bookingConfirmInput: {
          params: {
            bookingConfirmParams
          }
        }
      });
      if (!result) return false;

      setConfirmInfo(bookingConfirmInfo);
    } catch (error) {
      if (error instanceof GraphQLValidationError) {
        return setValidationError(error.validationError);
      }
    }
  }, [bookingConfirmParams, mutation, setConfirmInfo]);

  return {
    validationError,
    mutation,
    mutate,
  };
};

// 予約実行
export const useBookingAction = () => {
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const mutation = useBookingInputMutation();
  const bookable = recoilBookingInputSelectors.useBookable();
  const bookingParams = recoilBookingInputSelectors.useBookingParams();
  const booked = recoilBookingInputActions.useBooked();
  const mutate = useCallback(async ({ onSuccess }: { onSuccess?(booking: BookingInputMutation['bookingInput']['booking']): Promise<void> } = {}) => {
    // TODO: 予約に必要な情報が足りない場合は何かエラーを出したほうが良い
    if (!bookable || !bookingParams) return false;

    setIsSubmitting(true);
    try {
      const { bookingInput: { result, booking } } = await mutation.mutateAsync({
        bookingInput: {
          params: bookingParams
        }
      });

      if (!result) {
        setIsSubmitting(false);
        return false;
      }

      if (result) {
        booked();
        onSuccess && await onSuccess(booking);
      }
      setIsSubmitting(false);
      return result;
    } catch (error) {
      setIsSubmitting(false);
      if (error instanceof GraphQLValidationError) {
        return setValidationError(error.validationError);
      }
    }
  }, [bookable, booked, bookingParams, mutation]);

  return {
    validationError,
    mutation,
    mutate,
    isSubmitting,
  };
};
export const useBookingGuestAction = () => {
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const mutation = useBookingGuestInputMutation();
  const bookable = recoilBookingInputSelectors.useBookable();
  const bookingParams = recoilBookingInputSelectors.useBookingParams();
  const booked = recoilBookingInputActions.useBooked();
  const mutate = useCallback(async ({
    guestCustomerInfo,
    authenticationToken,
    onSuccess
  }: {
    guestCustomerInfo: GuestCustomerInput,
    authenticationToken: string,
    onSuccess?(booking: BookingGuestInputMutation['bookingGuestInput']['booking']): Promise<void>
  }) => {
    // TODO: 予約に必要な情報が足りない場合は何かエラーを出したほうが良い
    if (!bookable || !bookingParams) return false;

    setIsSubmitting(true);
    try {
      const { bookingGuestInput: { result, booking } } = await mutation.mutateAsync({
        bookingGuestInput: {
          params: {
            ...bookingParams,
            guestCustomerInfo,
            authenticationToken,
          },
        }
      });

      if (!result) {
        setIsSubmitting(false);
        return false;
      }

      if (result) {
        booked();
        onSuccess && await onSuccess(booking);
      }
      setIsSubmitting(false);
      return result;
    } catch (error) {
      setIsSubmitting(false);
      if (error instanceof GraphQLValidationError) {
        return setValidationError(error.validationError);
      }
    }
  }, [bookable, booked, bookingParams, mutation]);

  return {
    validationError,
    mutation,
    mutate,
    isSubmitting,
  };
};
